export const api = <D>(endpoint: string, config: any = {}) => {
  const apiUrl = process.env.REACT_APP_API || 'https://localhost/api/'

  return fetchUrl<D>(`${apiUrl}${endpoint}`, {
    ...config,
    credentials: 'include',
    headers: {
      ...config.headers,
    },
  })
}

export const mockApi = <D>(endpoint: string, config: any) =>
  fetchUrl<D>(`/api/${endpoint}`, config)

/**
 * Small wrapper around window.fetch.
 */
export const fetchUrl = <D>(
  endpoint: string,
  { body, ...customConfig }: RequestInit = {},
) => {
  const headers = { 'content-type': 'application/json' }
  const config: any = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  return fetch(endpoint, config)
    .then(async (response) => {
      const data = (await response.json()) as D
      if (response.ok) {
        return data
      } else {
        return Promise.reject({
          status: response.status,
          message: data,
        })
      }
    })
    .catch((err) => Promise.reject(err))
}
