import { createGlobalStyle } from 'styled-components'
export const GlobalStyles = createGlobalStyle`

  html {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  html, body {
    font-family: 'Myriad Regular', 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  #root {
    height: 100%;
  }

  :focus {
    outline: none;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9C9C9C;
    opacity: 1;
    font-size: 24px;
    font-style: italic;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #9C9C9C;
    font-size: 24px;
    font-style: italic;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #9C9C9C;
    font-size: 24px;
    font-style: italic;
  }

  .Toastify__toast--error {
    background: #3F3F3F;
  }

  .notificatiopn_firstline {
    font-family: 'Myriad SemiBold', 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
  }

  .notification_secondline {
    color: #A9A9A9;
    display: block;
    line-height: normal;
  }
`
