import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Box } from '@mui/material'
import { Sidebar } from 'components/Sidebar'
import { Dashboard } from 'pages/Dashboard'

export interface ActionsProps {
  open: boolean
}

export const Template = () => {
  return (
    <>
      <Sidebar />
      <Box
        sx={{
          position: 'relative',
          left: {
            lg: 280,
          },
          width: {
            lg: 'calc(100% - 280px)',
          },
        }}>
        <Router>
          <Switch>
            <Route exact path="/" component={Dashboard} />
          </Switch>
        </Router>
      </Box>
    </>
  )
}
