import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Button, ListItem } from '@mui/material'

export const NavItem = (props) => {
  const { href, icon, title, click, ...others } = props
  console.log(props)
  const router = useHistory()
  const active = href ? router.location.pathname === href : false

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 2,
      }}
      {...others}>
      <Button
        component="a"
        startIcon={icon}
        disableRipple
        onClick={click}
        sx={{
          backgroundColor: active ? 'rgba(255,255,255, 0.08)' : 'none',
          borderRadius: 1,
          color: active ? 'secondary.main' : 'neutral.300',
          fontWeight: active ? 'fontWeightBold' : 'normal',
          justifyContent: 'flex-start',
          px: 3,
          textAlign: 'left',
          textTransform: 'none',
          width: '100%',
          '& .MuiButton-startIcon': {
            color: active ? 'secondary.main' : 'neutral.400',
          },
          '&:hover': {
            backgroundColor: 'rgba(255,255,255, 0.08)',
          },
        }}>
        <Box sx={{ flexGrow: 1 }}>{title}</Box>
      </Button>
    </ListItem>
  )
}
