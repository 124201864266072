import React, { useState, useEffect } from 'react'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { API_URL } from 'utils/constants'
import logo from 'assets/svg/arcanebet-logo.svg'
import { Google as GoogleIcon } from 'assets/icons/google'

export const Login = (props: any) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({ show: false, message: '' })

  useEffect(() => {
    if (error.show) {
      setError({ show: false, message: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      sx={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        height: '100%',
      }}>
      <Box
        sx={{
          alignItems: 'center',
          alignContent: 'center',
          alignSelf: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <img src={logo} />
        <Button
          fullWidth
          color="error"
          startIcon={<GoogleIcon />}
          onClick={() => {
            window.open(`${API_URL}auth/google`, '_self')
          }}
          size="large"
          variant="contained"
          sx={{
            marginTop: '16px',
          }}>
          Login with Google
        </Button>
      </Box>
    </Box>
  )
}
