import env from 'env-var'

export enum Routes {
  DASHBOARD = '/',
}

export const NODE_ENV = env.get('NODE_ENV').required().asString()
export const APP_ENV = env.get('REACT_APP_ENV').asString() || 'development'
export const API_URL = env.get('REACT_APP_API').required().asString()
export const GOOGLE_CLIENT_ID = env
  .get('REACT_APP_GOOGLE_CLIENT_ID')
  .required()
  .asString()
