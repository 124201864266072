import { useState, useRef, useEffect } from 'react'
import { Box, Container, Grid } from '@mui/material'
import { RulesBlock } from './index'

export const Dashboard = () => {
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}>
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <RulesBlock />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}
