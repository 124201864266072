import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { AuthProvider } from 'providers/AuthProvider'
import { GlobalStyles } from 'theme/GlobalStyles'
import { theme } from './config/theme'
import { Routes } from 'utils/constants'
import { Dashboard } from 'pages/Dashboard'
import { Template } from 'pages'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AuthProvider>
        <Router>
          <Switch>
            <Route component={Template} />
          </Switch>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default App
