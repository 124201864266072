import {
  createContext,
  useEffect,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react'
import { Box, CircularProgress } from '@mui/material'
import { api } from 'api'
import { Login } from 'pages/Login'

type AuthContextType = {
  account: any
  login: () => Promise<void>
  logout: () => Promise<void>
}

export const AuthContext = createContext({} as AuthContextType)
AuthContext.displayName = 'AuthContext'

const AuthProvider: React.FC = ({ children }) => {
  let [type, setType] = useState('loading')
  let [loggedIn, setLoggedIn] = useState(false)
  let [account, setAccount] = useState(null)

  const checkLoggedInState = () => {
    api('user')
      .then((res: any) => {
        setAccount(res.payload)
        setLoggedIn(true)
        setType('done')
      })
      .catch(() => {
        setType('login')
        setLoggedIn(false)
      })
  }

  useEffect(() => {
    if (type === 'loading') checkLoggedInState()
  }, [type])

  /**
   * Login
   *
   * @returns
   */
  const login = useCallback(async () => {
    try {
      await api('user/login')
        .then((r) => {
          console.log(r)
        })
        .catch((e) => {
          throw new Error()
        })
    } catch (error) {}
  }, [])

  /**
   * Logout
   *
   * @returns
   */
  const logout = useCallback(async () => {
    try {
      await api('user/logout')
      setAccount(null)
      setType('loading')
    } catch (error) {
      console.log(error)
    }
  }, [])

  const store = useMemo(
    () => ({
      login,
      logout,
      account,
    }),
    [account, login, logout],
  )

  if (type === 'loading') {
    return (
      <Box
        sx={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          height: '100%',
        }}>
        <Box
          sx={{
            alignItems: 'center',
            alignContent: 'center',
            alignSelf: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <CircularProgress />
        </Box>
      </Box>
    )
  }

  if (type === 'login') {
    return <Login setType={(type: string) => setType(type)} />
  }

  if (type === 'done' && loggedIn) {
    return <AuthContext.Provider value={store}>{children}</AuthContext.Provider>
  }

  return <div></div>
}

export function useAuth() {
  return useContext(AuthContext)
}

export { AuthProvider }
