import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import {
  Box,
  Button,
  Divider,
  Drawer,
  Typography,
  useMediaQuery,
} from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { useAuth } from 'providers/AuthProvider'
import { ChartBar as ChartBarIcon } from 'assets/icons/chat-bar'
// import { Cog as CogIcon } from '../icons/cog'
// import { Lock as LockIcon } from '../icons/lock'
// import { Selector as SelectorIcon } from '../icons/selector'
// import { ShoppingBag as ShoppingBagIcon } from '../icons/shopping-bag'
// import { User as UserIcon } from '../icons/user'
// import { UserAdd as UserAddIcon } from '../icons/user-add'
// import { Users as UsersIcon } from '../icons/users'
// import { XCircle as XCircleIcon } from '../icons/x-circle'
import Logo from 'assets/svg/arcanebet-logo-white.svg'
import { NavItem } from 'components/nav-item'

const items = [
  {
    href: '/',
    icon: <ChartBarIcon fontSize="small" />,
    title: 'Dashboard',
  },
  {
    href: '/logout',
    icon: null,
    title: 'Logout',
  },
  //   {
  //     href: '/customers',
  //     icon: <UsersIcon fontSize="small" />,
  //     title: 'Customers',
  //   },
  //   {
  //     href: '/products',
  //     icon: <ShoppingBagIcon fontSize="small" />,
  //     title: 'Products',
  //   },
  //   {
  //     href: '/account',
  //     icon: <UserIcon fontSize="small" />,
  //     title: 'Account',
  //   },
  //   {
  //     href: '/settings',
  //     icon: <CogIcon fontSize="small" />,
  //     title: 'Settings',
  //   },
  //   {
  //     href: '/login',
  //     icon: <LockIcon fontSize="small" />,
  //     title: 'Login',
  //   },
  //   {
  //     href: '/register',
  //     icon: <UserAddIcon fontSize="small" />,
  //     title: 'Register',
  //   },
  //   {
  //     href: '/404',
  //     icon: <XCircleIcon fontSize="small" />,
  //     title: 'Error',
  //   },
]

export const Sidebar = (props) => {
  const { open, onClose } = props
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false,
  })
  const { logout } = useAuth()

  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}>
        <div>
          <Box sx={{ pt: 3, pl: 3, pr: 3 }}>
            <a>
              <img src={Logo} />
            </a>
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: '#2D3748',
            my: 3,
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          {items.map((item) => (
            <NavItem
              key={item.title}
              icon={item.icon}
              href={item.href}
              title={item.title}
              click={item.title === 'Logout' ? () => logout() : null}
            />
          ))}
        </Box>
        <Divider sx={{ borderColor: '#2D3748' }} />
        <Box
          sx={{
            px: 2,
            py: 3,
          }}>
          <Typography color="neutral.100" variant="subtitle2">
            What is this tool?
          </Typography>
          <Typography color="neutral.500" variant="body2">
            It's competition labs on steroids by Arcanebet
          </Typography>
        </Box>
      </Box>
    </>
  )

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            color: '#FFFFFF',
            width: 280,
          },
        }}
        variant="permanent">
        {content}
      </Drawer>
    )
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary">
      {content}
    </Drawer>
  )
}
